import React from "react";
import { NavLink } from "react-router-dom";

import "../../styles/Footer.css";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-body">
        <div className="footer-main">
          <div className="text-left mr-[3vw]">
            <h1 className="footer-heading">COMPANY</h1>
            <div>
              <ul>
                <li>
                  <NavLink to="/about" className="footer-link">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" className="footer-link">
                    Contact
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/careers" className="footer-link">
                    Career
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/investors" className="footer-link">
                    Investors
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sustainability" className="footer-link">
                    Sustainability
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-list">
            <h1 className="footer-heading">MEDIA</h1>
            <div>
              <ul>
                <li>
                  <NavLink to="/newandmedia/news" className="footer-link">
                    Latest News
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/newandmedia/media-centre-photos"
                    className="footer-link"
                  >
                    Photos
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/newandmedia/media-centre-videos"
                    className="footer-link"
                  >
                    Videos
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/newandmedia/otiva-events"
                    className="footer-link"
                  >
                    Events
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-list">
            <h1 className="footer-heading">OUR PRODUCTS</h1>
            <div>
              <ul>
                <li>
                  <NavLink to="/products" className="footer-link">
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/industries" className="footer-link">
                    Industries
                  </NavLink>
                </li>
                {/* <li>
                <NavLink className="footer-link">Innovations</NavLink>
              </li> */}
                <li>
                  <a
                    href="https://ebusiness.otivaindustries.com/"
                    target="_blank"
                    className="footer-link"
                    rel="noreferrer"
                  >
                    eBusiness
                  </a>
                </li>
                {/* <li>
                <NavLink className="footer-link">Material Finder</NavLink>
              </li> */}
              </ul>
            </div>
          </div>
          <div className="text-left lg:ml-[2vw]">
            <h1 className="footer-heading pb-1">GET IN TOUCH</h1>
            <NavLink to="/contact" className="footer-btn">
              CONTACT US
            </NavLink>
          </div>
          <div></div>
        </div>
        <div className="footer-policy mt-4">
          <ul className="flex flex-wrap justify-between text-left">
            <li>
              <NavLink to="/terms-of-use" className="footer-link policy-link">
                Terms of Use
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/data-protection"
                className="footer-link policy-link"
              >
                Privacy Notice
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/terms-conditions-sale"
                className="footer-link policy-link"
              >
                Terms of Sale
              </NavLink>
            </li>
            <li>
              <NavLink to="/cookie-policy" className="footer-link policy-link">
                Cookie Policy
              </NavLink>
            </li>
            <li className="text-gray-400 mt-4 md:mt-0">&copy; {currentYear} OTIVA Industries Ltd.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
