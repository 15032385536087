import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "../styles/ScrollAnimation.css"; // Ensure correct path

const ScrollAnimation = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Keeps triggering on scroll
    threshold: 0.2, // Adjust for early/late visibility
  });

  const [scrollDir, setScrollDir] = useState("down");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const currentScrollY = window.scrollY;
      setScrollDir(currentScrollY > lastScrollY ? "down" : "up");
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => window.removeEventListener("scroll", updateScrollDirection);
  }, []);

  return (
    <div
      ref={ref}
      className={`fade-in ${inView ? (scrollDir === "down" ? "slide-up" : "slide-down") : ""}`}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;
