import React from "react";
import "../../styles/notfound.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const NotFound = () => {
  const vanish = useSelector((state) => state.navbarReducer.data);
  return (
    <div
      className={`notfound ${vanish ? "page_content_vanish" : "page_content"}`}
    >
      <div className="inner-normal">
        <img
          src={
            "https://res.cloudinary.com/dchlu4kif/image/upload/v1739247881/notfound_gt6e6w.jpg"
          }
          alt="not-found"
          id="notfound"
        />
        <div className="bg-white p-[2vw] lg:w-[30vw] md:h-[25vh] lg:h-[30vh]  text-left overlay">
          <div className="text-[#939598] text-2xl">404 Not Found.</div>
          <div className="mt-4">
            <NavLink to="/" className="link text-[#009fdf] text-[0.77rem]">
              BACK TO HOME
            </NavLink>
            <span className="mt-2">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-[#009fdf] ml-2"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
