import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import ScrollAnimation from "../../components/ScrollAnimation";
import "../../styles/Landing.css";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Landing = () => {
  const vanish = useSelector((state) => state.navbarReducer.data);
  useEffect(() => {
    document.title = "Otiva Industries Limited - Home";
  }, []);
  return (
    <div className={`${vanish ? "page_content_vanish" : "page_content"}`}>
        <div className="page_component homepage_carousel">
          <div className="inner">
          <div id="landing_background"></div> 
            <div id="landing_content">
              <h1 id="landing_tagline">Shaping Tomorrow, Today</h1>
              <hr id="landing_rule" />

              <NavLink to="/about">
                <button className="btn">
                  About Us{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="ml-1"
                    id="arrow"
                  />
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      <ScrollAnimation>
        <div className="page_component gray">
          <div className="inner text-left" id="landing_about">
            <span className="sm:w-[30vw]">
              <h1 className="heading text-gray-500">ABOUT OTIVA Ind.</h1>
            </span>
            <span className="lg:w-[30vw] md:text-[18px] landing_desc">
              <p>
                From making automobiles more fuel-efficient, to contributing in
                pharmaceuticals, we find solutions to the challenges of today to
                help our customers achieve their ambitions and build a better
                tomorrow.
              </p>
            </span>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="page_component">
          <div className="inner" id="landing_industry">
            <div className="text-left">
              <h1 className="heading text-gray-500">OUR INDUSTRIES</h1>
            </div>
            <div className="flex-widget pt-6 sm:pt-8 lg:pt-2">
              <div className="industry_item">
                <img src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247879/automotive_ejwwex.svg"} alt="automotive" />
                <NavLink to="/industries/automotive">
                  <h2 className="flex-text link">AUTOMOTIVE</h2>
                </NavLink>
              </div>

              <div className="industry_item">
                <img src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247879/building_m0glmd.svg"} alt="building" />
                <NavLink to="/industries/building-and-constructions">
                  <h2 className="flex-text link w-[30vw]">
                    BUILDING & CONSTRUCTION
                  </h2>
                </NavLink>
              </div>

              <div className="industry_item">
                <img src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247879/consumer_k4aqek.svg"} alt="consumer" />
                <NavLink to="/industries/consumer">
                  <h2 className="flex-text link">CONSUMER</h2>
                </NavLink>
              </div>
            </div>
            <div className="flex-widget">
              <div className="industry_item">
                <img src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247879/electrical_qsc7n2.svg"} alt="electrical" />
                <NavLink to="/industries/electrical-and-electronics">
                  <h2 className="flex-text link w-[30vw]">
                    ELECTRICAL & ELECTRONICS
                  </h2>
                </NavLink>
              </div>
            </div>
            <div className="flex justify-normal mt-6">
              <NavLink
                to="/industries"
                className="sublink text-[12px] font-semibold"
              >
                OUR INDUSTRIES
              </NavLink>
              &nbsp;
              <span className="text-blue-500 -mt-1 icons">
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="page_component blue">
          <div className="inner">
            <div>
              <img
                src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247882/products_i3dfbg.jpg"}
                alt="A man looking at tablet containing report"
                className="images"
              />
            </div>
            <div className="landing_description text-white">
              <h1 className="heading">PRODUCTS</h1>
              <p className="normal-text font-semibold mb-8">
                We gain insights by understanding our customers’ needs and use
                our technical expertise to tailor solutions to help maximize
                their market advantage moving forward.
              </p>
              <div>
                <NavLink
                  to="/products"
                  className="link text-[12px] font-semibold"
                >
                  OUR PRODUCTS
                </NavLink>
                &nbsp;
                <FontAwesomeIcon className="icons" icon={faChevronRight} />
                {/* <span>>></span> */}
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="page_component">
          <div className="inner">
            <div className="landing_description mr-8 md:p-4">
              <h1 className="heading text-gray-400 mb-4">SUSTAINABILITY</h1>
              <p className="normal-text font-semibold mb-8 text-gray-500">
                Sustainability is a vital part of our core business strategy.
                We're making sure we understand the megatrends that will impact
                on our lives in the years to come.
              </p>
            </div>
            <div>
              <img
                src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247881/sustainability_xlbnsf.jpg"}
                alt="sustainability"
                className="images"
              />
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="center_content">
          <hr className="section_separator" />
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="page_component">
          <div className="inner">
            <div>
              <img
                src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247880/investor_pbqmmn.webp"}
                alt="A man looking at tablet containing report"
                className="images"
              />
            </div>
            <div className="landing_description">
              <h1 className="heading text-gray-400">INVESTORS</h1>
              <p className="normal-text font-semibold text-gray-500 mb-8">
                We’re committed to long-term investments and business
                partnerships, by harnessing innovation, learning and operational
                excellence.
              </p>
              <NavLink
                to="/investors"
                className="sublink text-[12px] font-semibold"
              >
                INVESTORS
              </NavLink>
              &nbsp;
              <FontAwesomeIcon
                className="text-blue-500 icons"
                icon={faChevronRight}
              />
              {/* <span >>></span> */}
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="page_component bg-yellow-400 text-gray-600">
          <div className="inner">
            <div className="landing_description">
              <h1 className="heading ">CAREERS</h1>
              <p className="normal-text font-semibold mb-8">
                People have helped us to develop, produce and market innovative
                solutions that make a difference both to industry and to
                humanity. At OTIVA we enable you to realize your ideas and make
                an impact.
              </p>
              <NavLink
                to="/careers"
                className="link text-[12px] font-semibold "
              >
                VIEW JOB OPPORTUNITIES
              </NavLink>
              &nbsp;
              <FontAwesomeIcon className="icons" icon={faChevronRight} />
              {/* <span>>></span> */}
            </div>
            <div>
              <img src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247879/careers_dhlt4w.jpg"} alt="careers" className="images md:-mr-6" />
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="page_component text-gray-600">
          <div className="inner">
            <div>
              <img src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247880/brands_gredkw.jpg"} alt="brands" className="images" />
            </div>
            <div className="landing_description">
              <h1 className="heading">OUR BRAND</h1>
              <p className="normal-text font-semibold mb-8">
                We believe the answer to some of the world's biggest challenges
                lies in the natural human instinct to collaborate. Together
                we're Shaping Tomorrow, Today<sup>TM</sup>&nbsp;.
              </p>
              <NavLink
                to="/about/our-brand"
                className="sublink text-[12px] font-semibold"
              >
                OUR BRAND
              </NavLink>
              &nbsp;
              <FontAwesomeIcon
                className="text-blue-500 icons"
                icon={faChevronRight}
              />
              {/* <span className="text-blue-500">>></span> */}
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Landing;
