import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";

import Subnavbar from "./Subnavbar";
import Blur from "./Blur";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMailBulk,
  faGlobe,
  faUser,
  faCartShopping,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";
import { useDispatch } from "react-redux";
import { setGVanish } from "../../actions/navbar";

const Navbar = () => {
  const [hovered, setHovered] = useState(false);
  const [itemValue, setItemValue] = useState("");
  const [vanish, setVanish] = useState(false);
  const [ptoggle, setPtoggle] = useState(false);
  const [stoggle, setStoggle] = useState(false);
  const [blur, setBlur] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const dispatch = useDispatch();

  //determinig device type
  const [deviceType, setDeviceType] = useState("");
  const determineDeviceType = () => {
    const width = window.innerWidth;
    if (width <= 480) {
      setDeviceType("Phone");
    } else if (width <= 900) {
      setDeviceType("Tablet");
    } else {
      setDeviceType("Desktop");
    }
  };

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 10 && deviceType === "Desktop") {
      setVanish(true);
      dispatch(setGVanish(true));
    } else {
      setVanish(false);
      dispatch(setGVanish(false));
    }
  }, [deviceType, dispatch]);

  //nav menu primary toggle state
  useEffect(() => {
    determineDeviceType();

    if (deviceType === "Desktop") {
      setPtoggle(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if ((ptoggle || stoggle) && deviceType !== "Desktop") {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    if (deviceType !== "Desktop" && ptoggle) {
      setBlur(true);
    } else {
      setBlur(false);
    }
  }, [ptoggle, stoggle, deviceType]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (
        event.clientY > window.innerHeight * 0.77 ||
        event.clientY < 0.1 ||
        itemValue === "null" ||
        (vanish && event.clientY > window.innerHeight * 0.69)
      ) {
        setHovered(false);
        setItemValue("");
      }
    };
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [itemValue, vanish]);

  useEffect(() => {
    if (deviceType === "Desktop") {
      const handleScrollEvent = () => {
        setHovered(false);
        setItemValue("");
        handleScroll();
      };
      window.addEventListener("scroll", handleScrollEvent);

      return () => {
        window.removeEventListener("scroll", handleScrollEvent);
      };
    }
  }, [deviceType, handleScroll]);

  useEffect(() => {
    let lastScrollY = 0;
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const isAtFooter = scrollY + windowHeight >= documentHeight - 10;

      if (isAtFooter) {
        setIsVisible(true);
      } else if (scrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  console.log(
    "hovered",
    "deviceType",
    "ptoggle",
    "stoggle",
    "vanish",
    hovered,
    deviceType,
    ptoggle,
    stoggle,
    vanish
  );
  return (
    <div
      className={`navbar ${deviceType !== "Desktop" ? "flex flex-col" : ""}`}
    >
      <Subnavbar
        trigger={hovered}
        vanish={vanish}
        setHovered={setHovered}
        ptoggle={ptoggle}
        stoggle={stoggle}
        setPtoggle={setPtoggle}
        setStoggle={setStoggle}
        setTrigger={setHovered}
        deviceType={deviceType}
        onClose={hovered}
        item={itemValue}
      />
      <Blur trigger={hovered || blur} setTrigger={setHovered} vanish={vanish} />

      {/* mobile phone navbar */}
      <div
        className={`mobile-nav  ${
          deviceType !== "Desktop" ? "fixed" : "vanish"
        } ${isVisible ? "visible" : "hidden-nav"}`}
      >
        <div>
          {stoggle ? (
            <img
              src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739248345/arrow-left_ormsn4.png"}
              className={`w-[16px] -mt-1 ml-4 font-extrabold ${
                deviceType === "Tablet" ? "ml-[4vw]" : ""
              }`}
              alt="close"
              onMouseEnter={() => {
                setHovered(false);
                setItemValue("");
              }}
              onClick={() => {
                setStoggle(false);
              }}
            />
          ) : (
            <NavLink to="/">
              <img
                src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739248346/favicon_tngrcl.png"}
                className={`w-[30px] ml-4`}
                alt="home"
                onMouseEnter={() => {
                  setHovered(false);
                  setItemValue("");
                }}
              />
            </NavLink>
          )}
        </div>

        <div className={`mobile-widget`}>
          <ul className="flex justify-between">
            <li
              className={`navlink ${itemValue === "search" ? "active" : ""} ${
                ptoggle ? "vanish" : ""
              } ${stoggle ? "vanish" : ""}`}
              onMouseEnter={() => {
                setHovered(true);
                setItemValue("search");
              }}
              onClick={() => {
                setHovered(!hovered);
                setStoggle(!stoggle);
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </li>

            <li
              className={`navlink ${itemValue === "cart" ? "active" : ""} ${
                ptoggle ? "vanish" : ""
              } ${stoggle ? "vanish" : ""}`}
              onMouseEnter={() => {
                setHovered(true);
                setItemValue("cart");
              }}
              onClick={() => {
                setHovered(!hovered);
                setStoggle(!stoggle);
              }}
            >
              <FontAwesomeIcon icon={faCartShopping} />
            </li>
            <li
              className={`navlink toggle ${stoggle ? "vanish" : ""} ${
                ptoggle ? "vanish" : ""
              }`}
              onClick={() => {
                setPtoggle(true);
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </li>
            <li
              className={`navlink toggle   ${ptoggle ? "" : "vanish"}`}
              onClick={() => {
                setPtoggle(false);
                setStoggle(false);
                setHovered(false);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </li>
          </ul>
        </div>
      </div>

      <div className={`nav-1 w-[80%] ml-8`}>
        <div
          className={`nav-1-1 -mt-8 ${
            deviceType !== "Desktop" ? "vanish" : ""
          }`}
        >
          <img
            src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247881/tagline_ixsbp8.png"}
            className={`tagline ${vanish ? "vanish" : ""}`}
            alt="tagline"
          />
        </div>
        <div
          className={`nav-1-2 flex -mt-1 ml-1  ${
            vanish
              ? "fixed mt-8 ml-[1.5vw] w-[75vw] p-4 bg-white z-50 opacity-[0.9]"
              : ""
          } ${
            deviceType !== "Desktop"
              ? `${ptoggle ? "fixed -mt-[16vh]" : "-mt-[10vh] vanish"}`
              : ""
          } `}
        >
          <div
            className={`nav-1-2-1 w-[70%] transition-all duration-300 ${
              ptoggle ? "transition-enter" : " transition-exit vanish"
            } ${deviceType === "Tablet" ? `${ptoggle ? `pl-[4vw]` : ``}` : ``}`}
          >
            <ul className="navlist flex justify-between ml-10">
              <NavLink to="/">
                <li>
                  <img
                    src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739248346/home_bo4fgf.jpg"}
                    className={`w-[30px] ${vanish ? "vanish" : ""} ${
                      deviceType !== "Desktop" ? "vanish" : ""
                    }`}
                    alt="home"
                    onMouseEnter={() => {
                      setHovered(false);
                      setItemValue("");
                    }}
                  />
                </li>
              </NavLink>
              <NavLink to="/">
                <li>
                  <img
                    src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247881/logo_iu6ywk.jpg"}
                    className={`w-[30px] ${vanish ? "-mt-1 mr-4" : "vanish"} ${
                      ptoggle ? "" : ""
                    }`}
                    alt="home"
                    onMouseEnter={() => {
                      setHovered(false);
                      setItemValue("");
                    }}
                  />
                </li>
              </NavLink>

              <li
                className={`navlink ${itemValue === "About" ? "active" : ""}`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("About");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                About
              </li>

              <li
                className={`navlink ${
                  itemValue === "Products" ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("Products");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                Products
              </li>

              <li
                className={`navlink ${
                  itemValue === "Industries" ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("Industries");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                Industries
              </li>

              <li
                className={`navlink ${
                  itemValue === "Sustainability" ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("Sustainability");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                Sustainability
              </li>

              <li
                className={`navlink ${itemValue === "Careers" ? "active" : ""}`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("Careers");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                Careers
              </li>

              <li
                className={`navlink ${
                  itemValue === "Investors" ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("Investors");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                Investors
              </li>

              <li
                className={`navlink ${
                  itemValue === "News and Media" ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("News and Media");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                News and Media
              </li>
            </ul>
          </div>

          <div
            className={`nav-1-2-2 w-[15%] ml-12 transition-all duration-300 ${
              ptoggle
                ? `${
                    deviceType !== "Desktop" ? "ml-[2vw] transition-enter" : ""
                  }`
                : "transition-exit vanish"
            } ${deviceType === "Tablet" ? `${ptoggle ? `pl-[4vw]` : ``}` : ``}`}
          >
            <ul className="widgetlist flex justify-between">
              <li
                className={`navlink ${itemValue === "search" ? "active" : ""} ${
                  deviceType !== "Desktop" ? `${ptoggle ? "vanish" : ""}` : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("search");
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </li>
              <li
                className={`navlink ${
                  itemValue === "contact" ? "active" : ""
                } ${
                  deviceType === "Phone" ? "-ml-[25vw] mb-[4vh] mt-[2vh]" : ""
                } ${
                  deviceType === "Tablet"
                    ? "-ml-[29.5vw] mb-[4vh] mt-[2vh]"
                    : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("contact");
                }}
                onClick={() => {
                  setStoggle(!stoggle);
                }}
              >
                <span
                  className={`sm:mr-[2vw] ${
                    deviceType === "Desktop" ? `${ptoggle ? "vanish" : ""}` : ""
                  }`}
                >
                  Contact Us
                </span>
                <FontAwesomeIcon icon={faMailBulk} />
              </li>

              {/* language option to be added latter */}
              <li
                className={`navlink ${
                  itemValue === "language" ? "active" : ""
                } ${
                  deviceType !== "Desktop" ? `${ptoggle ? "vanish" : ""}` : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("language");
                }}
              >
                <FontAwesomeIcon icon={faGlobe} />
              </li>

              <li
                className={`navlink ${itemValue === "cart" ? "active" : ""} ${
                  deviceType !== "Desktop" ? `${ptoggle ? "vanish" : ""}` : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("cart");
                }}
              >
                <FontAwesomeIcon icon={faCartShopping} />
              </li>
              <li
                className={`navlink   ${
                  itemValue === "account" ? "active" : ""
                } ${deviceType === "Phone" ? "-ml-[25vw]" : ""} ${
                  deviceType === "Tablet"
                    ? "-ml-[29.5vw] mb-[4vh] mt-[2vh]"
                    : ""
                }`}
                onMouseEnter={() => {
                  setHovered(true);
                  setItemValue("account");
                }}
                onClick={() => {
                  setItemValue("account");
                  setHovered(!hovered);
                  setStoggle(!stoggle);
                }}
              >
                <span
                  className={` sm:mr-[2vw] ${
                    deviceType === "Desktop" ? `${ptoggle ? "vanish" : ""}` : ""
                  }`}
                >
                  User
                </span>
                <FontAwesomeIcon icon={faUser} />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`nav-2 ${deviceType !== "Desktop" ? "vanish" : ""}`}>
        <img
          src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247881/logo_iu6ywk.jpg"}
          className={`logo w-[7vw] ${vanish ? "vanish" : ""}`}
          alt="logo"
        />
      </div>
    </div>
  );
};

export default Navbar;
