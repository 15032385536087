import React from "react";
import "./Blur.css";

function Blur(props) {
  return props.trigger ? (
    <div
      className={`${props.rotate ? `rotate-blur` : `blur`} ${
        props.vanish ? "h-[90vh]" : "h-[25vh]"
      }`}
    >
      {props.rotate ? (
        <>
          <div style={{ zIndex: "100" }}>
            <img
              src={"https://res.cloudinary.com/dchlu4kif/image/upload/v1739247880/landscape_m3dtcz.jpg"}
              alt="landscape mode warning"
              style={{ height: "310px", width: "60vw" }}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  ) : (
    ""
  );
}

export default Blur;
