// const initialState = {
//     initialDimensions: null,
//     currentDimensions: null,
//   };
  
  const dimensionReducer = (state = {data:null}, action) => {
    switch (action.type) {
      case "SET_INITIAL_DIMENSIONS":
        return { ...state, data: action.payload };
      case "SET_CURRENT_DIMENSIONS":
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };
  
  export default dimensionReducer;
  