import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setInitialDimensions } from "./actions/dimensions";

import "./App.css";
import DefaultLayout from "./Layouts/DefaultLayout";
import Landing from "./pages/landing/Landing.jsx";
import ScrolltoTop from "./components/ScrolltoTop.jsx";
import NotFound from "./pages/NotFound/NotFound.jsx";
import Blur from "./components/navbar/Blur.jsx";
function App() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust for tablets if needed
    };
    // Function to detect orientation change
    const detectOrientation = () => {
      const orientation =
        window.screen.orientation?.type ||
        (window.innerWidth > window.innerHeight ? "landscape" : "portrait");

      dispatch(setInitialDimensions(orientation));
    };

    // Initial check
    checkIfMobile();
    detectOrientation();

    // Add event listeners for orientation change & resize
    window.addEventListener("resize", () => {
      checkIfMobile();
      detectOrientation();
    });
    window.screen.orientation?.addEventListener("change", detectOrientation);


    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("resize", checkIfMobile);
      window.removeEventListener("resize", detectOrientation);
      window.screen.orientation?.removeEventListener("change", detectOrientation);
    };
  }, [dispatch]);

  const dimensions = useSelector((state) => state.dimensionsReducer.data);

  return (
    <div className="App">
      {isMobile && dimensions.includes("landscape") ? (
        <Blur trigger={true} vanish={false} rotate={true} />
      ) : (
        <Router>
          <ScrolltoTop />
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
